var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teaserSlider" }, [
    _c("div", { staticClass: "teaserSlider__container" }, [
      _c(
        "div",
        { staticClass: "teaserSlider__col" },
        [
          _c("span", { staticClass: "teaserSlider__subTitle" }, [
            _vm._v(_vm._s(_vm.teaserSlider.subTitle)),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "teaserSlider__title",
            domProps: { innerHTML: _vm._s(_vm.titleHyphen) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "teaserSlider__teaser" }, [
            _vm._v(_vm._s(_vm.teaserSlider.teaser)),
          ]),
          _vm._v(" "),
          _vm.hasButton
            ? _c("Btn", { attrs: { href: _vm.teaserSlider.buttonLink } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.teaserSlider.buttonText) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "teaserSlider__col" }, [
        _c(
          "div",
          { staticClass: "teaserSlider__colInner" },
          [
            _vm.sliderItems
              ? _c(
                  "hooper",
                  {
                    key: "slider-" + _vm.hooperKey,
                    ref: "hooper",
                    attrs: {
                      transition: 0,
                      itemsToShow: _vm.itemsToShow,
                      trimWhiteSpace: "",
                    },
                    on: { slide: _vm.updateCarousel },
                  },
                  _vm._l(_vm.sliderItems, function (item, index) {
                    return _c(
                      "slide",
                      {
                        key: "sliderItem-" + index,
                        staticClass: "teaserSlider__item",
                      },
                      [
                        _c(
                          "link-wrap",
                          { attrs: { link: item.slug } },
                          [
                            item.teaserImage
                              ? _c("image-tag", {
                                  staticClass: "teaserSlider__itemImage",
                                  attrs: {
                                    definition: "teaser",
                                    image: item.teaserImage,
                                  },
                                })
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "teaserSlider__itemImage teaserSlider__itemImage--empty",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "teaserSlider__itemImageGradient",
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "teaserSlider__itemTitle" },
                              [_vm._v(_vm._s(item.title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "teaserSlider__itemText" },
                              [
                                _vm._v(
                                  _vm._s(_vm.limitTeaserText(item.teaserText))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "teaserSlider__itemLink" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("magazine.list.more")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.sliderItems.length > _vm.itemsToShow
              ? _c("RangeSlider", {
                  attrs: { max: _vm.maxSliderBar, "for-teaser": "" },
                  model: {
                    value: _vm.carouselData,
                    callback: function ($$v) {
                      _vm.carouselData = $$v
                    },
                    expression: "carouselData",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }