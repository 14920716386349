
































































import {isEmpty} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Hooper, Pagination as HooperPagination, Slide} from 'hooper';
import RangeSlider from '../../../Component/Form/RangeSlider/RangeSlider.vue';
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/antd.css';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';
import SliderMixin from '../../../Mixin/SliderMixin';
import {HyphenFormatter} from '../../../Service/HyphenFormatter';

export default {
    name: 'TeaserSlider',
    components: {
        RangeSlider,
        LinkWrap, ImageTag, Btn, Hooper, Slide, HooperPagination
    },
    mixins: [SliderMixin, ComponentProxyAwareMixin],
    props: {
        context: ContentElementContext
    },
    computed: {
        maxSliderBar()
        {
            return parseFloat((this.sliderItems.length - this.itemsToShow).toFixed(1));
        },
        sliderLength()
        {
            return this.itemsToShow === 3 ? this.sliderItems.length - this.itemsToShow
                : this.sliderItems.length - 1;
        },
        sliderItems()
        {
            return this.context.data.get('items', []);
        },
        teaserSlider()
        {
            return this.context.data.get('teaserSlider', []);
        },
        titleHyphen()
        {
            return HyphenFormatter.hyphen(this.teaserSlider.title);
        },
        hasButton(): Boolean
        {
            return !isEmpty(this.teaserSlider.buttonText);
        }
    },
    methods: {
        limitTeaserText(text)
        {
            return text.length >= 180 ? text.substring(0, 180) + '...' : text;
        }
    }
};
